.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-content {
  position: fixed;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 100px;
}

.App-timeline {
  text-align: center;
  max-width: 80%; 
  min-width: 50%; 
  max-height: 50%;
}

.App-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
}

